import { getMapUrl } from '@/api/map'
import { getAddressInfo } from '@/api/system'
import wx from 'weixin-js-sdk'

export default {
  data() {
    return {
      address: '',
      name: ''
    }
  },
  methods: {
    // 弹框关闭
    async beforeClose(action, done) {
      if (action === 'confirm') {
        const value = /wxwork/i.test(navigator.userAgent)
        const flag =
          navigator.userAgent.match(
            /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
          ) != null
        if (value && flag) {
          const { data: { data } } = await getAddressInfo(this.address)
          if (data.location) {
            const location = data.location
            wx.openLocation({
              latitude: location.lat, // 纬度，浮点数，范围为90 ~ -90
              longitude: location.lng, // 经度，浮点数，范围为180 ~ -180。
              name: this.name, // 位置名
              address: this.address, // 地址详情说明
              scale: 16, // 地图缩放级别,整形值,范围从1~28。默认为16
              fail: async () => {
                const { data } = await getMapUrl('amap', this.address)
                window.location.href = data.data
              }
            })
            done()
          } else {
            const { data } = await getMapUrl('amap', this.address)
            window.location.href = data.data
            done()
          }
        } else {
          const { data } = await getMapUrl('amap', this.address)
          window.location.href = data.data
          done()
        }
      } else {
        done()
      }
    },
    // 地址定位跳转
    async addressClickTo(address, name) {
      this.address = address
      this.name = name || ''
    }
  }
}
