<template>
  <div class="companyAddressInfo">
    <!-- 顶部导航 -->
    <commonNavBar
      :title="'设备卡号信息详情'"
      v-watermark
    ></commonNavBar>
    <!-- 加载中  -->
    <van-overlay :show="loadingShow">
      <van-loading
        text-color="#0094ff"
        color="#0094ff"
        vertical
      >加载中...</van-loading>
    </van-overlay>
    <div
      class="info"
      v-watermark
    >
      <van-collapse
        v-model="activeNames"
        accordion
      >
        <van-collapse-item
          title="设备卡号信息"
          name="1"
        >
          <van-cell
            center
            title="通讯卡号:"
            :value="CompanyInfo.cardNumber || '--'"
          />
          <van-cell
            center
            title="卡号类型:"
            :value="CompanyInfo.cardNumberType || '--'"
          />
          <van-cell
            center
            title="状态:"
          >
            <template #default>
              <span
                style="color: #00a854"
                v-if="CompanyInfo.status === 0"
              >已激活</span>
              <span
                style="color: #d9001b"
                v-else-if="CompanyInfo.status === 1"
              >停用</span>
              <span
                style="color: #aaaaaa"
                v-else-if="CompanyInfo.status === 2"
              >未激活</span>
              <span v-else>--</span>
            </template>
          </van-cell>
          <van-cell
            center
            title="运营商:"
            :value="CompanyInfo.operator || '--'"
          />
          <van-cell
            center
            title="绑定设备:"
          >
            <template #default>
              <p
                :style="{ color: '#1990ff' }"
                v-if="CompanyInfo.bindEquipment && CompanyInfo.bindEquipment.code
                "
                @click="bindEquipmentClick(CompanyInfo.bindEquipment.id)"
              >
                {{ CompanyInfo.bindEquipment.code }}
              </p>
              <p v-else>--</p>
            </template>
          </van-cell>
          <van-cell
            center
            title="支持设备:"
            :value="CompanyInfo.equipment || '--'"
          />
          <van-cell
            center
            title="来源类型:"
            :value="CompanyInfo.sourceType | sourceTypeFilter"
          />
          <van-cell
            center
            title="卡号来源:"
          >
            <template #default>
              <p
                :style="{ color: '#1990ff' }"
                v-if="CompanyInfo.supplier && CompanyInfo.supplier.name"
                @click="companyClick(CompanyInfo.supplier.id)"
              >
                {{ CompanyInfo.supplier.name }}
              </p>
              <p v-else>--</p>
            </template>
          </van-cell>
          <userJump
            title="领用人:"
            :userList="CompanyInfo.collectPeople"
          ></userJump>
          <van-cell
            center
            title="领用日期:"
            :value="CompanyInfo.collectDatetime | openYmdFormat"
          />
          <van-cell
            center
            title="更新时间:"
            :value="CompanyInfo.updateDatetime | dateFormat"
          />
          <userJump
            title="更新人:"
            :userList="CompanyInfo.updateBy"
          ></userJump>
        </van-collapse-item>
      </van-collapse>
    </div>
    <!-- <van-action-sheet
      description="请选择定位地图"
      v-model="showPosition"
      :actions="positionActions"
      cancel-text="取消"
      close-on-click-action
      @select="mapSelect"
    /> -->
  </div>
</template>

<script>
import { getCardNumberInfo } from '@/api/cardNumber'
import { Dialog, ImagePreview } from 'vant'
import { mapState } from 'vuex'
import wx from 'weixin-js-sdk'
import index from '@/mixins'

export default {
  mixins: [index],
  name: 'companyAddressInfo',
  data() {
    return {

      activeNames: '1',
      CompanyInfo: {},
      loadingShow: false
      // showPosition: false,
      // positionActions: [{ name: '高德地图' }, { name: '百度地图' }]
    }
  },
  async created() {
    this.loadingShow = true
    try {
      const { data } = await getCardNumberInfo(this.$route.params.id)
      if (data.data) {
        this.CompanyInfo = data.data
        wx.onMenuShareAppMessage({
          title: data.data.cardNumber || '--', // 分享标题
          desc: `通讯卡号:${data.data.cardNumber ? data.data.cardNumber : '--'
            }\n运营商:${data.data.operator
              ? data.data.operator
              : '--'
            }\n支持设备:${data.data.equipment
              ? data.data.equipment
              : '--'
            }`, // 分享描述
          link: '', // 分享链接；在微信上分享时，该链接的域名必须与企业某个应用的可信域名一致
          imgUrl: 'https://is.shfas96119.com/word/FAS.png', // 分享图标
          enableIdTrans: 1, // 是否开启id转译，不填默认为0
          success: function () {
            // 用户确认分享后执行的回调函数
          },
          error: function (res) {
            alert('暂无分享权限')
          },
          cancel: function () {
            // 用户取消分享后执行的回调函数
          }
        })
        this.loadingShow = false
      } else {
        this.loadingShow = false
        this.$toast.fail({
          message: '该设备卡号已被删除,请联系管理员!',
          duration: 500
        })
      }
    } catch (error) {
      this.loadingShow = false
      if (
        error?.response?.status === 401
      ) {
        return
      }
      if (error.message.includes('timeout')) {
        this.$toast.fail({
          message: '网络不好，请稍后再试!',
          duration: 500
        })
        return
      }
      if (error.response.data.status === 'E00030050') {
        this.$toast.fail({
          message: '该设备卡号已被删除,请联系管理员!',
          duration: 500
        })
        return
      }
      this.$toast.fail({
        message: '当前网络异常，请稍后再试!!',
        duration: 500
      })
    }
  },
  computed: {
    ...mapState('user', ['jurisdiction'])
  },
  methods: {
    // 设备跳转
    bindEquipmentClick(id) {
      if (id) {
        if (this.CompanyInfo.equipmentType === 0) {
          this.$router.push({
            name: 'companyTerminalAddressBookInfo',
            params: {
              Refresh: true,
              id: id
            }
          })
        } else if (this.CompanyInfo.equipmentType === 1) {
          this.$router.push({
            name: 'companyInternetAddressBookInfo',
            params: {
              Refresh: true,
              id: id
            }
          })
        }
      } else {
        this.$toast.fail({
          message: '暂无相关权限,请联系管理员!',
          duration: 500
        })
      }
    },
    // 卡号来源跳转
    companyClick(id) {
      if (id && this.jurisdiction.includes('supplier:info')) {
        this.$router.push(`/workTable/supplierAddressInfo/${id}`)
      } else {
        this.$toast.fail({
          message: '暂无相关权限,请联系管理员!',
          duration: 500
        })
      }
    },
    // 领用人跳转
    collectPeopleClick(id) {
      this.$router.push(`/personalAddressInfo/${id}`)
    },
    // 呼叫手机
    callOut(val) {
      Dialog.confirm({
        title: '信息确认',
        message: `确认呼叫${val}?`
      })
        .then(() => {
          window.location.href = `tel:${val}`
        })
        .catch(() => {
          // on cancel
        })
    },
    // 报废原因弹框
    scrapReasonHandler(text) {
      Dialog.alert({
        title: '不合格原因',
        message: text,
        confirmButtonColor: '#1989FA'
      }).then(() => {
        // on close
      })
    },
    // 营业执照预览
    qualifications(path) {
      ImagePreview({
        images: ['/api/file/' + path],
        closeable: true
      })
    },
    // 主营业务展示
    businessRangeHandler(text) {
      Dialog.alert({
        title: '主营业务',
        message: text,
        confirmButtonColor: '#1989FA'
      }).then(() => {
        // on close
      })
    },
    // 相关资质展示
    relevantQualificationsHandler(text) {
      Dialog.alert({
        title: '相关资质',
        message: text,
        confirmButtonColor: '#1989FA'
      }).then(() => {
        // on close
      })
    }
  },
  filters: {
    // 来源类型
    sourceTypeFilter(val) {
      switch (val) {
        case 0:
          return '联网单位'
        case 1:
          return '供应商'
        default:
          return '--'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.companyAddressInfo {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: auto;

  ::v-deep {
    .van-overlay {
      .van-loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .ellipsis {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }

    .info {
      height: calc(100% - 46px);
      overflow: auto;

      .van-collapse {
        background-color: transparent;

        .van-collapse-item {
          background-color: transparent;

          .van-collapse-item__title {
            background-color: white;
            font-weight: 700;
          }

          .van-collapse-item__wrapper {
            background-color: transparent;

            .van-collapse-item__content {
              background-color: transparent;
            }
          }
        }
      }
    }
  }
}
</style>
