import http from '@/units/request'
/** *
 * 获取地图定位url
 * ***/
export const getMapUrl = (type, search) => {
  return http({
    url: `/is-system/base/getMapLink/${type}/${search}`,
    method: 'get'
  })
}
